import slickModule from './slick'
slickModule()
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';

$(function () {
  'use strict'

  const baseUrl = $('[data-home-url]').data('home-url').replace(/\/$/, '')
  const ua = navigator.userAgent
  const isMobile = /iPhone|iPod|Android.*Mobile/.test(ua)

  // /* **************************************
  //     margin
  // ************************************** */
  const fixedHeader = $('.fix-header')
  const fixedFooter = $('.fixed-footer-nav')
  const contentHeight = fixedHeader.height()
  const fixedFooterHeight = fixedFooter.height()
  const anchorHeight = contentHeight * 2

  $('.header-space').css('padding-top', contentHeight)
  $('.fixed-banner').css('margin-top', contentHeight + 32 + 'px')
  $('.page-content-r').css('top', contentHeight)
  $('.anchor').css({
    paddingTop: anchorHeight,
    marginTop: `-${anchorHeight}px`,
  })
  if (isMobile) {
    $('.footer, .fixed-banner').css('padding-bottom', `${fixedFooterHeight}px`)
  }

  // /* **************************************
  //     margin
  // ************************************** */
  // スマホの下部固定ナビ分の高さ調整
  // (() => {
  //   'use strict';
  //   const ua = navigator.userAgent;
  //   const isMobile = /iPhone|iPod|Android.*Mobile/.test(ua);
  //
  //   if (isMobile) { // mobile devices
  //     $('.footer, .fixed-banner').css('padding-bottom', `${fixedFooterHeight}px`);
  //   }
  // })();

  // /* **************************************
  //     ヘッダーナビ　メニューボタン
  // ************************************** */
  // menu content
  const fixMenu = $('.fix-menu')
  const headerMenu = $('.header-menu')
  const closeButton = $('.close')
  const overlay = $('.menu-overlay')
  fixMenu.css('transform', `translateY(${contentHeight}px)`)
  headerMenu.on('click', function () {
    $(this).toggleClass('on')
    $('html, body').addClass('no-scroll')
    overlay.fadeIn()
    fixMenu.stop().slideToggle()
    if (!headerMenu.hasClass('on')) {
      overlay.fadeOut()
      $('html, body').removeClass('no-scroll')
    }
  })
  closeButton.on('click', function () {
    headerMenu.removeClass('on')
    overlay.fadeOut()
    $('html, body').removeClass('no-scroll')
    fixMenu.slideUp()
  })

  // /* **************************************
  //     scroll top
  // ************************************** */
  const scrollToAnchor = (anchor) => {
    const fixHeader = document.querySelector('.fix-header')
    const htmlBody = document.querySelector('html, body')
    const href = anchor.getAttribute('href')
    const target = href && href !== '#' ? document.querySelector(href) : document.documentElement
    const position = target.offsetTop + fixHeader.offsetHeight

    htmlBody.scrollTo({
      top: position,
      behavior: 'smooth'
    })
  }

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault()
      scrollToAnchor(anchor)
    })
  })


  // /* **************************************
  //     slider
  // ************************************** */
  // お墓詳細
  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  })
  $('.slider-for').find('.slick-next, .slick-prev').css('margin-top', '-40px');
  $('.slider-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    variableWidth: true
  })

  // お墓マガジン記事内
  $('.recommended-graves-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    variableWidth: true
  })

  // /* **************************************
  //     fixed banner
  // ************************************** */
  const windowtHeight = $(window).height()
  $(window).scroll(function () {
    if ($(this).scrollTop() >= windowtHeight) {
      $('.fixed-banner').addClass('show')
    }
  })
  $('.icon-close').on('click', function () {
    $('.fixed-banner').fadeOut()
  })

  // /* **************************************
  //     accordion
  // ************************************** */
  $('.question').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
      $(this).siblings('.answer').slideUp()
    } else {
      $(this).siblings('.answer').slideDown()
      $('.question').not($(this)).siblings('.answer').slideUp()
      $(this).addClass('active')
      $('.question').not($(this)).removeClass('active')
    }
  })

  // simulation area
  const trig = $(".accordion2-trig-icon")
  const acd2Element = $('.accordion2-cnt')
  $('.accordion2-trig-icon').on('click', function () {
    const index = trig.index(this)
    if (trig.eq(index).hasClass('on')) {
      trig.eq(index).removeClass('on')
      $(acd2Element).eq(index).slideUp(300)
    } else {
      trig.eq(index).addClass('on')
      $(acd2Element).eq(index).slideDown(300)
    }
  })
  // simulation button
  $('.simulation-select-item label').on('click', function () {
    $(this).toggleClass('on')
  })

  // customer voice
  $(document).ready(function () {
    $('.customer-cnt').each(function () {
      const $customerFaqs = $(this).find('.customer-faq')
      const $loadMoreButton = $('<button class="load-more"><span class="btn btn-radius">▼ もっと見る</span></button>')

      if ($customerFaqs.length > 1) {
        // 最初の要素以外を非表示にする
        $customerFaqs.slice(1).hide()

        // もっと見るボタンを追加
        $loadMoreButton.appendTo($(this))

        // もっと見るボタンがクリックされたときのイベントリスナーを設定
        $loadMoreButton.click(function () {
          $customerFaqs.slice(1).slideDown()
          $loadMoreButton.remove()
        })
      }
    })
  })

  $(document).ready(function() {
    const isMobile = window.matchMedia("(max-width: 640px)").matches;

    function initLoadMore() {
      if (isMobile) {
        let itemsToShow = 1;
        let items = $('.recom_detail');
        let totalItems = items.length;

        items.show();

        if (totalItems > itemsToShow) {
          items.slice(itemsToShow).hide();
          $('#load-more-recom').show();
        } else {
          $('#load-more-recom').hide();
        }

        $('#load-more-recom').on('click', function() {
          let hiddenItems = items.filter(':hidden');
          let itemsToShowNow = hiddenItems.slice(0, itemsToShow);
          $('.load-more-line').hide();
          itemsToShowNow.slideDown();
          if (hiddenItems.length <= itemsToShow) {
            $(this).hide();
          }
        });
      } else {
        $('.recom_detail').show();
        $('#load-more-recom').hide();
      }
    }

    initLoadMore();

    $(window).on('resize', function() {
      initLoadMore();
    });
  });


  // /* **************************************
  //     table of contents
  // ************************************** */
  $(document).ready(function () {
    const heddingElements = $('#magazine h2, #magazine h3')
    if (heddingElements.length && $('#toc-block').length) {
      const tocElements = $('<div class="table-of-contents bg-blue-l" />')
      tocElements.append('<div class="bg-img" />')
      tocElements.append(function () {
        const bgCnt = $('<div class="bg-cnt" />')
        const ttl = $('<div class="ttl" />')
        const accordionBtnWrap = $('<div class="icon accordion-btn" />')
        ttl.append('<p>目次</p>')
        accordionBtnWrap.append('<svg width="18" height="13.5" version="1.1" id="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12.1 7.4" style="enable-background:new 0 0 12.1 7.4;" xml:space="preserve"><path class="st0" d="M10.7,7.4L6.1,2.8L1.5,7.4L0.1,6l6-6l6,6L10.7,7.4z" fill="343434" /></svg>')
        ttl.append(accordionBtnWrap)
        bgCnt.append(ttl)
        bgCnt.append(function () {
          const toc = $('<div id="toc" class="table-of-contents-cnt accordion" />')
          let heddingNum = 1
          heddingElements.map(function () {
            const ttl = $(this).text()
            const lv = this.nodeName.toLowerCase()
            $(this).attr('id', `ttl-${heddingNum}`)
            toc.append(`<div class="table-of-contents-list lv_${lv}"><a href="#${$(this).attr('id')}" class="link">${ttl}</a></div>`)
            heddingNum++
          })
          return toc
        })
        bgCnt.append('<p class="center accordion-btn"><span class="btn btn-radius main">閉じる</span></p>')
        return bgCnt
      })
      $('#toc-block').append(tocElements)
      $('.table-of-contents .accordion-btn').on('click', function () {
        const acdElement = $('.accordion-btn, .accordion')
        if (acdElement.hasClass('on')) {
          acdElement.removeClass('on')
          $('.accordion-btn span').text('閉じる')
        } else {
          acdElement.addClass('on')
          $('.accordion-btn span').text('開く')
        }
      })
    }
  })

  /* **************************************
      modal
  ************************************** */
  const modalOpenBtn = $('[data-modal-open]')
  if (modalOpenBtn.length) {
    modalOpenBtn.on('click', function () {
      const modalName = $(this).data('modal-id')
      const modalElement = $(`#${modalName}`)
      const modalClose = modalElement.find('.modal-close')
      modalElement.show()
      modalClose.on('click', function () {
        modalElement.hide()
      })
      $(document).on('click', function (e) {
        if ($(e.target).data('close-modal') !== undefined) {
          modalElement.hide()
        }
      })
    })
  }

  /**
   * 登録寺院数を取得
   * @param payload
   * @returns {Promise<String|Number>}
   */
  function getTempleCount(payload = null) {
    const opt = {
      url: `${baseUrl}/wp-json/api/v1/temple/count`,
      type: 'GET',
      async: true,
      contentType: 'application/json',
      dataType: 'json'
    }
    if (payload) {
      opt.data = payload
    }
    return new Promise((resolve, reject) => {
      $.ajax(opt)
        .then(function (result) {
          resolve(result)
        }, function () {
          reject()
        })
    })
  }

  function getTempleCountParams(pattern = null) {
    const params = {}
    if (!pattern || pattern === 'area') {
      // 都道府県
      const areaChecked = $('[name="area[]"]:checked')
      if (areaChecked.length) {
        const areas = []
        areaChecked.map(function () {
          areas.push($(this).val())
        })
        params.area = areas
      }
    }
    if (!pattern || pattern === 'sub') {
      // お墓のプラン
      const planChecked = $('[name="plan[]"]:checked')
      if (planChecked.length) {
        const plans = []
        planChecked.map(function () {
          plans.push($(this).val())
        })
        params.plan = plans
      }
      // 条件・こだわり
      const conditionsCommitmentChecked = $('[name="conditions_commitment[]"]:checked')
      if (conditionsCommitmentChecked.length) {
        const conditionsCommitments = []
        conditionsCommitmentChecked.map(function () {
          conditionsCommitments.push($(this).val())
        })
        params.conditions_commitment = conditionsCommitments
      }
      // 特徴
      const templeFeatureChecked = $('[name="feature[]"]:checked')
      if (templeFeatureChecked.length) {
        const templeFeatures = []
        templeFeatureChecked.map(function () {
          templeFeatures.push($(this).val())
        })
        params.temple_feature = templeFeatures
      }
      // 施設・設備
      const templeFacilityChecked = $('[name="facility[]"]:checked')
      if (templeFacilityChecked.length) {
        const templeFacilities = []
        templeFacilityChecked.map(function () {
          templeFacilities.push($(this).val())
        })
        params.temple_facility = templeFacilities
      }
      // 宗旨宗派
      const templeReligionChecked = $('[name="religion[]"]:checked')
      if (templeReligionChecked.length) {
        const templeReligions = []
        templeReligionChecked.map(function () {
          templeReligions.push($(this).val())
        })
        params.temple_religion = templeReligions
      }
    }
    return params
  }

  /**
   * 登録寺院数を動的に取得し、指定の要素に反映する
   * @returns {Promise<void>}
   */
  async function setTempleCount() {
    const templeCountElement = $('[data-temple-count]')
    if (templeCountElement.length) {
      templeCountElement.map(async function () {
        switch ($(this).data('temple-count')) {
          case 'area':
            $(this).text(await getTempleCount(getTempleCountParams('area')))
            break
          case 'sub':
            $(this).text(await getTempleCount(getTempleCountParams('sub')))
            break
          default:
            $(this).text(await getTempleCount(getTempleCountParams()))
            break
        }
      })
      const searchTriggerElement = $('[data-search-temple-trigger]')
      if (searchTriggerElement.length) {
        $(searchTriggerElement).on('change', async function () {
          templeCountElement.map(async function () {
            switch ($(this).data('temple-count')) {
              case 'area':
                $(this).text(await getTempleCount(getTempleCountParams('area')))
                break
              case 'sub':
                $(this).text(await getTempleCount(getTempleCountParams('sub')))
                break
              default:
                $(this).text(await getTempleCount(getTempleCountParams()))
                break
            }
          })
        })
      }
    }
  }
  setTempleCount()

  /**
   * 一括チェックボックスでページ内対象のチェックボックスにチェックする
   */
  const templeAllCheckBox = $('[name="temple_all"]')
  const templeCheckElements = $('[name="temple_id[]"]')
  const bulkSendBtnElement = $('[data-bulk-send-btn]')
  if (templeAllCheckBox.length) {
    templeAllCheckBox.on('change', function () {
      if ($(this).prop('checked')) {
        templeCheckElements.prop('checked', true)
        bulkSendBtnElement.addClass('is-active')
        bulkSendBtnElement.prop('disabled', false)
      } else {
        templeCheckElements.prop('checked', false)
        bulkSendBtnElement.removeClass('is-active')
        bulkSendBtnElement.prop('disabled', true)
      }
    })
  }
  if (bulkSendBtnElement.length) {
    templeCheckElements.on('change', function () {
      if ($('input[name="temple_id[]"]:checked').length) {
        bulkSendBtnElement.addClass('is-active')
        bulkSendBtnElement.prop('disabled', false)
      } else {
        bulkSendBtnElement.removeClass('is-active')
        bulkSendBtnElement.prop('disabled', true)
      }
    })
  }

  /**
   * 同意ボタンの非活性化
   */
  const disabledCheckElement = $('[data-submit-disabled-check-trigger]')
  const disabledSubmitElement = $('[data-submit-disabled]')
  if (disabledCheckElement.length && disabledSubmitElement.length) {
    disabledCheckElement.on('change', function () {
      if ($(this).prop('checked')) {
        disabledSubmitElement.attr('data-submit-disabled', '')
        disabledSubmitElement.find('input, button').prop('disabled', false)
      } else {
        disabledSubmitElement.attr('data-submit-disabled', 'disabled')
        disabledSubmitElement.find('input, button').prop('disabled', true)
      }
    })
  }

  /**
   * Cookieからutmパラメータを取得し、フォーム値に反映する
   */
  const utmParams = {
    utm_campaign: null,
    utm_source: null,
    utm_medium: null,
    utm_content: null,
    utm_term: null
  }
  const cookieLists = document.cookie
  if (cookieLists) {
    const cookies = cookieLists.split(';')
    cookies.forEach(function (cookieVal) {
      const vals = cookieVal.split('=')
      const cookieName = vals[0].replace(/ /, '')
      const cookieValue = vals[1]
      Object.keys(utmParams).forEach(function (key) {
        if (key === cookieName) {
          utmParams[key] = cookieValue
        }
      })
    })
  }
  Object.keys(utmParams).forEach(function (key) {
    if (utmParams[key]) {
      $(`[name="${key}"]`).val(utmParams[key])
    }
  })

  /**
   * submit前にpayloadの調整をする
   * トリガーはtype="button"にすること
   */
  $('[data-change-param]').on('click', function () {
    if ($('input[data-checkbox="grave_type"]').length) {
      $('[name="grave_type"]').val('')
      if ($('input[data-checkbox="grave_type"]:checked').length) {
        $('[name="grave_type"]').val($('input[data-checkbox="grave_type"]:checked').map(function () {
          return $(this).val()
        })
          .get()
          .join(','))
      }
    }
    if ($('input[data-checkbox="temple"]').length) {
      $('[name="temple"]').val('')
      if ($('input[data-checkbox="temple"]:checked').length) {
        $('[name="temple"]').val($('input[data-checkbox="temple"]:checked').map(function () {
          return $(this).val()
        })
          .get()
          .join(','))
      }
    }
    $('[data-request-form]').submit()
  })

  /**
   * フォームバリデーション
   */
  $('form[data-request-form]').submit(function (e) {
    let isSubmitReady = true
    const _self = $(this)
    _self.find('[data-required-error]').hide()
    _self.find('[data-kana-error]').hide()
    _self.find('[data-email-error]').hide()
    _self.find('[data-tel-error]').hide()
    _self.find('[data-validation]').map(function () {
      const inputName = $(this).data('validation-name') === undefined ? $(this).attr('name') : $(this).data('validation-name')
      const validationTypeLists = $(this).data('validation').split(',')
      const isRequired = !!validationTypeLists.find(type => type === 'required')
      const isKana = !!validationTypeLists.find(type => type === 'kana')
      const isEmail = !!validationTypeLists.find(type => type === 'email')
      const isTel = !!validationTypeLists.find(type => type === 'tel')
      if (isRequired) {
        if ($(this).data('validation-name') === undefined) {
          if (!$(this).val()) {
            isSubmitReady = false
            _self.find(`[data-required-error="${inputName}"]`).show()
          }
        } else {
          if (!$(this).find(`[name^="${inputName}"]:checked`).length) {
            isSubmitReady = false
            _self.find(`[data-required-error="${inputName}"]`).show()
          }
        }
      }
      if (isKana) {
        if ($(this).val()) {
          if (!$(this).val().match(/^[ァ-ヶー　]+$/)) {
            isSubmitReady = false
            _self.find(`[data-kana-error="${inputName}"]`).show()
          }
        }
      }
      if (isEmail) {
        if ($(this).val()) {
          if (!$(this).val().match(/.+@.+\..+/)) {
            isSubmitReady = false
            _self.find(`[data-email-error="${inputName}"]`).show()
          }
        }
      }
      if (isTel) {
        if ($(this).val()) {
          if (!$(this).val().match(/^0\d{9,10}$/)) {
            isSubmitReady = false
            _self.find(`[data-tel-error="${inputName}"]`).show()
          }
        }
      }
    })
    if (!isSubmitReady) return false
  })

  /**
   * シミュレーションフォームの送信制御
   *
   * @param _self
   * @param nameSelector
   * @param dataSimulationName
   */
  function setSimulationForm(_self, nameSelector, dataSimulationName) {
    $(nameSelector).prop('checked', false)
    $(`[data-simulation-value="${dataSimulationName}"]`).removeClass('on')
    _self.addClass('on')
    _self.parent('.simulation-select-item').find(nameSelector).prop('checked', true)
    $(`[data-simulation-form="${dataSimulationName}"]`).submit()
  }

  $('[data-simulation-value="question1"]').on('click', function () {
    setSimulationForm($(this), '[name="question_1"]', 'question1')
  })
  $('[data-simulation-value="question2"]').on('click', function () {
    setSimulationForm($(this), '[name="question_2"]', 'question2')
  })
  $('[data-simulation-value="question3"]').on('click', function () {
    setSimulationForm($(this), '[name="question_3"]', 'question3')
  })

  /**
   * シミュレーションの一括チェックボックスの制御
   */
  const simulationAllCheckElement = $('[data-simulation-all-check]')
  simulationAllCheckElement.on('change', function () {
    const groupKey = $(this).data('simulation-all-check')
    if ($(this).prop('checked')) {
      $(`[data-simulation-check="${groupKey}"]`).prop('checked', true)
      // ボタンの活性
      $('[data-simulation-submit="btn"]').prop('disabled', false)
    } else {
      $(`[data-simulation-check="${groupKey}"]`).prop('checked', false)
      // ボタンの非活性
      if (!$('[name="question_4[]"]:checked').length) {
        $('[data-simulation-submit="btn"]').prop('disabled', true)
      }
    }
  })
  $('[data-simulation-check]').on('change', function () {
    if (!$(this).prop('checked')) {
      const groupKey = $(this).data('simulation-check')
      $(`[data-simulation-all-check="${groupKey}"]`).prop('checked', false)
      // ボタンの非活性
      if (!$('[name="question_4[]"]:checked').length) {
        $('[data-simulation-submit="btn"]').prop('disabled', true)
      }
    } else {
      // ボタンの活性
      $('[data-simulation-submit="btn"]').prop('disabled', false)
    }
  })

  /**
   * シミュレーションQ4のボタン押下後少し待機する
   */
  const simulationQ4Submit = $('[data-simulation-form="question4"]')
  simulationQ4Submit.submit(function (e) {
    $('[data-simulation-submit="overlay"]').fadeIn('fast', function () {
      $('[data-simulation-submit="loading"]').fadeIn('fast')
    })
    e.preventDefault()
    setTimeout(function () {
      simulationQ4Submit.off('submit')
      simulationQ4Submit.submit()
    }, 1500)
  })

  /**
   * シミュレーション最後のフォームでemailを入力でボタンを活性化
   */
  function updateSubmitButtonState() {
    let emailVal = $('[data-simulation-email]').val()
    let telVal = $('[data-simulation-tel]').val()
    let isButtonEnabled = false
    if(emailVal && telVal){
      isButtonEnabled = true
    }
    $('[data-simulation-submit="result-request"]').prop('disabled', !isButtonEnabled)
  }

  $('[data-simulation-email], [data-simulation-tel]').on('input', updateSubmitButtonState)


  /**
   * カレンダークリック
   */
  function getDayOfWeek(dateStr) {
    const days = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
    const date = new Date(dateStr);
    return days[date.getDay()];
  }

  $('[data-calendar-date-click]').on('click', function () {
    let _val = $(this).data('calendar-date-click');
    if (_val) {
      const targetForm = $(this).data('target-form');
      const dayOfWeek = getDayOfWeek(_val);
      _val = `${_val},${dayOfWeek}`;
      $(`form[data-calender-form="${targetForm}"]`)
        .find('select[name="tour_date"]')
        .val(_val);
    }
  });

  /**
   *　寺院一覧チェックボックスのチェック数を動的に表示する
   */
  const templeCheckBox = $('input[name="temple_id[]"]');
  const templeCheckBoxAll = $('[name="temple_all"]');
  const labelNum = $('.label-num');

  templeCheckBox.on('change', function () {
    var $cnt = $('input[name="temple_id[]"]:checked').length;
    labelNum.text($cnt);
  }).trigger('change');
  templeCheckBoxAll.on('change', function () {
    var $cnt = $('input[name="temple_id[]"]:checked').length;
    labelNum.text($cnt);
  }).trigger('change');
})



/**
 * モーダル分割後の検索の値引き継ぎ設定
 */

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('#areas-modal-id .btn-round.white').forEach((link, index) => {
    const originalHref = link.getAttribute('href');
    const url = new URL(originalHref, window.location.origin);
    url.searchParams.delete('area[]');
    link.setAttribute('href', url.toString());

    link.addEventListener('click', function(event) {
      event.preventDefault();

      const newUrl = new URL(this.getAttribute('href'), window.location.origin);
      const currentParams = new URLSearchParams(window.location.search);

      currentParams.forEach((value, key) => {
        if (key !== 'area[]') {
          newUrl.searchParams.set(key, value);
        }
      });
      window.location.href = newUrl.toString();
    });
  });
});

document.addEventListener("DOMContentLoaded", function() {
  // フォーム送信時の処理
  var forms = document.querySelectorAll(".form-search");
  forms.forEach(function(form) {
    form.addEventListener("submit", function(event) {
      // 現在のURLのクエリパラメータを取得
      var queryParams = new URLSearchParams(window.location.search);

      // 既存の隠し入力を削除
      form.querySelectorAll('.dynamic-hidden-input').forEach(function(el) {
        el.remove();
      });

      // フォーム内の既存のフィールド値を取得
      var existingFieldValues = getExistingFieldValues(form);

      // 現在のクエリパラメータをチェックして、フォームに含まれないものを追加
      queryParams.forEach(function(value, key) {
        if (!existingFieldValues[key] || !existingFieldValues[key].includes(value)) {
          addHiddenInputToForm(form, key, value);
        }
      });
    });
  });

  let areaLinks = document.querySelectorAll('#areas-modal-id .btn-round.white');
  areaLinks.forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault();

      const newUrl = new URL(this.getAttribute('href'), window.location.origin);

      window.location.href = newUrl.toString();
    });
  });
});

document.addEventListener("DOMContentLoaded", function() {
  var currentKeyword = new URLSearchParams(window.location.search).get('keyword') || '';
  var keywordInput = document.querySelector('#search-modal-id input[name="keyword"]');
  if (keywordInput) {
    keywordInput.value = currentKeyword;
    keywordInput.addEventListener('change', function(event) {
      currentKeyword = event.target.value;
    });
  }

  var forms = document.querySelectorAll(".form-search");
  forms.forEach(function(form) {
    form.addEventListener("submit", function(event) {
      // 既存のkeyword入力要素を削除
      var existingKeywordInput = form.querySelector('input[type="hidden"][name="keyword"]');
      if (existingKeywordInput) {
        existingKeywordInput.remove();
      }

      // keywordが空でなければ、隠し入力要素を追加
      if (currentKeyword) {
        var hiddenKeywordInput = document.createElement("input");
        hiddenKeywordInput.type = "hidden";
        hiddenKeywordInput.name = "keyword";
        hiddenKeywordInput.value = currentKeyword;
        form.appendChild(hiddenKeywordInput);
      }
    });
  });
});


function addHiddenInputToForm(form, name, value) {
  var input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  input.classList.add('dynamic-hidden-input');
  form.appendChild(input);
}

function getExistingFieldValues(form) {
  var values = {};
  Array.from(form.elements).forEach(function(el) {
    if (el.name && !values[el.name]) {
      values[el.name] = [];
    }
    if (el.name && el.value) {
      values[el.name].push(el.value);
    }
  });
  return values;
}

/**
 * 新規検索ボタン用の処理
 */
document.addEventListener("DOMContentLoaded", function() {
  var searchForm = document.getElementById('search-button-id');
  var selectedConditions = {};

  var queryParams = new URLSearchParams(window.location.search);
  queryParams.forEach(function(value, key) {
    if (!selectedConditions[key]) {
      selectedConditions[key] = [];
    }
    selectedConditions[key].push(value);
  });

  function monitorModalInputs(modalId) {
    var modal = document.getElementById(modalId);
    var inputs = modal.querySelectorAll('input, select, textarea');

    inputs.forEach(function(input) {
      input.addEventListener('change', function() {
        if (input.type === 'checkbox') {
          if (!selectedConditions[input.name]) {
            selectedConditions[input.name] = [];
          }
          if (input.checked) {
            selectedConditions[input.name].push(input.value);
          } else {
            selectedConditions[input.name] = selectedConditions[input.name].filter(function(value) {
              return value !== input.value;
            });
          }
        } else {
          selectedConditions[input.name] = input.value;
        }
      });
    });
  }

  monitorModalInputs('search-modal-id');
  monitorModalInputs('areas-modal-id');
  monitorModalInputs('sub-area-modal-id');
  monitorModalInputs('temple-modal-id');
  monitorModalInputs('detail-modal-id');

  searchForm.addEventListener('submit', function(event) {
    var existingHiddenInputs = searchForm.querySelectorAll('input[type="hidden"]');
    existingHiddenInputs.forEach(function(input) {
      input.remove();
    });

    for (var name in selectedConditions) {
      var value = selectedConditions[name];
      if (Array.isArray(value)) {
        value.forEach(function(val) {
          var hiddenInput = document.createElement('input');
          hiddenInput.type = 'hidden';
          hiddenInput.name = name;
          hiddenInput.value = val;
          searchForm.appendChild(hiddenInput);
        });
      } else {
        var hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = name;
        hiddenInput.value = value;
        searchForm.appendChild(hiddenInput);
      }
    }
  });
});

/**
 * 子カテゴリ（市区町村用）検索ロジック
 */
document.addEventListener('DOMContentLoaded', function() {
  // ページロード時のチェックボックスの状態設定
  let currentPath = window.location.pathname.split('/').pop();
  document.querySelectorAll('.label-checkbox-input').forEach(checkbox => {
    if (checkbox.value === currentPath) {
      checkbox.checked = true;
    }
  });

  document.querySelectorAll('.area-link').forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault();

      // クリックされたリンクのhrefに基づいて新しいURLを作成（クエリパラメータなし）
      const newUrl = new URL(this.href.split('?')[0], window.location.origin);

      // 新しいURL（クエリパラメータなし）に遷移
      window.location.href = newUrl.toString();
    });
  });
});

/**
 * トグルボタン
 */
document.addEventListener('DOMContentLoaded', function() {
  var toggleTitles = document.querySelectorAll('.detail-search-toggle .ttl');

  toggleTitles.forEach(function(title, index) {
    var checkGroup = title.nextElementSibling;
    if (window.innerWidth <= 580) {
      if (index === 0) {
        checkGroup.style.display = 'block';
        title.classList.add('active');
      } else {
        checkGroup.style.display = 'none';
      }
    }
    title.addEventListener('click', function() {
      if (window.innerWidth <= 580) {
        var isVisible = checkGroup.style.display === 'block';
        checkGroup.style.display = isVisible ? 'none' : 'block';
        title.classList.toggle('active', !isVisible);
      }
    });
  });

  window.addEventListener('resize', function() {
    toggleTitles.forEach(function(title, index) {
      var checkGroup = title.nextElementSibling;
      if (window.innerWidth <= 580) {
        checkGroup.style.display = (index === 0) ? 'block' : 'none';
        title.classList.toggle('active', index === 0);
      } else {
        checkGroup.style.display = 'block';
        title.classList.remove('active');
      }
    });
  });
});



/**
 * 動的にpath切り替え処理
 */
const calenderRequestPath = document.querySelector('[data-form-path]');

if (calenderRequestPath) {
  const url = 'https://www2.eitaikuyou.net';
  const path = calenderRequestPath.dataset.formPath;
  const requestUrl = url + path;

  const calenderRequestButton = document.querySelector('[data-change-param]');
  if (calenderRequestButton) {
    calenderRequestButton.addEventListener('click', function(event) {
      if (calenderRequestPath.checkValidity()) {
        calenderRequestPath.action = requestUrl;
      } else {
        event.preventDefault();
        calenderRequestPath.reportValidity();
      }
    });
  }
}



/**
 * フォーム送信時にsuccesslocationに値を含める
 */

document.addEventListener('DOMContentLoaded', () => {
  const fieldsToSave = ['last_name', 'first_name', 'last_kana', 'first_kana', 'email', 'tel'];
  fieldsToSave.forEach(fieldName => {
    const inputField = document.querySelector(`input[name="${fieldName}"]`);
    if (inputField) {
      inputField.addEventListener('input', (event) => {
        const fieldValue = event.target.value;
        setCookie(fieldName, fieldValue, 1);
      });
    }
  });

  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const path = window.location.pathname;
  if (path.startsWith('/document-request/complete')) {
    setFormData();
  }
  function setFormData() {
    function getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const fields = ['last_name', 'first_name', 'last_kana', 'first_kana', 'email', 'tel'];
    fields.forEach(field => {
      const value = getCookie(field);
      if (value) document.querySelector(`input[name="${field}"]`).value = value;
    });
  }
});

document.addEventListener('DOMContentLoaded', function() {
  function deleteCookie(name) {
    document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  }
  function deleteFormCookies() {
    const fields = ['last_name', 'first_name', 'last_kana', 'first_kana', 'email', 'tel'];
    fields.forEach(field => {
      deleteCookie(field);
    });
  }
  const button = document.querySelector('.submit.calendar-submit[data-change-param]');
  if (button && window.location.pathname.endsWith('/document-request/complete')) {
    button.addEventListener('click', deleteFormCookies);
  }
});

/**
 * カレンダー表示
 */

$(document).ready(function() {
  let $tourDateDisplay = $("#tour_date_display");
  let $hiddenTourDate = $("#tour_date");
  let $datepickerContainer = $("#datepicker-container")[0];;
  
  // フォーム高さを変更する関数
  function updateFormBoxMargin() {
    let calendarFormHeight = $("#calendar-form").outerHeight();
    $("#form-box").css("margin-top", calendarFormHeight + 30 + "px");
  }
 
  // 3ヶ月後の末日を計算する関数
  let today = new Date();
  today.setHours(0, 0, 0, 0); // 時間をリセットして、日付のみで比較できるようにする
  function getLastDateOfThreeMonthsLater() {
    let threeMonthsLater = new Date(today.getFullYear(), today.getMonth() + 4, 0);
    return threeMonthsLater;
  }

  // 日付と曜日を組み合わせたフォーマットを作成する関数（送信用）
  function createDateFormat(dateText){
    let date = new Date(dateText);
      let daysOfWeek = ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];
      let dayOfWeek = daysOfWeek[date.getDay()];
      // 日付と曜日を組み合わせたフォーマット（送信用）
      let dateWithDayOfWeek = dateText + ',' + dayOfWeek;

      return dateWithDayOfWeek;
  }

  // 12月30日から1月4日かを判定する関数
  function isCheckHolidays(date){
    let month = date.getMonth() + 1; // 月は0から始まるので+1
    let day = date.getDate();

    return (month === 12 && day >= 30) || (month === 1 && day <= 4);
  }

  // 日付範囲のオプションを生成し、<select>に追加
  function populateDateSelect() {
    let maxDate = getLastDateOfThreeMonthsLater();
    let currentDate = new Date(today);
    
    while (currentDate <= maxDate) {
      if (isCheckHolidays(currentDate)) {
        currentDate.setDate(currentDate.getDate() + 1);
        continue;
      }

      let optionText = $.datepicker.formatDate('yy-mm-dd', currentDate);
      let displayText = $(".old-calendar-form").length ? $.datepicker.formatDate('yy年mm月dd日', currentDate) : optionText;
      let $option = $("<option>").val(optionText).text(displayText);
      $tourDateDisplay.append($option);
      currentDate.setDate(currentDate.getDate() + 1); // 次の日付に移動
    }
    $tourDateDisplay.val($.datepicker.formatDate('yy-mm-dd', today)).change();
  }

  populateDateSelect(); // ページ読み込み時に <select> に日付オプションを追加

  // <select> の変更イベントで hidden フィールド及びカレンダーの高さを更新
  $tourDateDisplay.on('change', function() {
    let selectedDate = $(this).val();
    let dateWithDayOfWeek = createDateFormat(selectedDate);
    $hiddenTourDate.val(dateWithDayOfWeek);

    // datepickerの選択日付を変更
    $("#datepicker-container").datepicker("setDate", selectedDate);
    updateFormBoxMargin;
  });
    
  // ウィンドウリサイズ時にカレンダーの高さを更新
  $(window).on("resize", updateFormBoxMargin);

  // MutationObserver を使って datepicker の内容変更を監視
  let observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      updateFormBoxMargin(); // DOM が変更されたら高さをカレンダーの高さを更新
    });
  });
  observer.observe($datepickerContainer, { childList: true, subtree: true });

  // 祝日データを取得してdatepickerを初期化
  $.get("https://holidays-jp.github.io/api/v1/date.json", function(holidaysData) {
    $("#datepicker-container").datepicker({
      beforeShowDay: function(date) {
        let ymd = $.datepicker.formatDate('yy-mm-dd', date);
        let isSelectable = true;
        let className = '';

        if (date < today) {
          className = 'past-date';
        } else if (isCheckHolidays(date)) {
          className = ' holidays';
          isSelectable = false;
        }
        else{
          className = 'future-date';
        }
    
        // 曜日や祝日条件を追加で判定
        if (date.getDay() === 0) {
          className = className + ' ' + 'day-sunday';
        } else if (date.getDay() === 6) {
          className = className + ' ' + 'day-saturday';
        } else if (holidaysData[ymd]) {
          className = className + ' ' + 'day-holiday';
        } else{
          className = className + ' ' + 'day-weekday'; 
        }
        
        return [isSelectable, className, null];
      },
      dateFormat: 'yy-mm-dd',
      minDate: 0,
      maxDate: getLastDateOfThreeMonthsLater(),
      onSelect: function(dateText, inst) {
        let dateWithDayOfWeek = createDateFormat(dateText);
        // 表示用フィールドを変更
        $tourDateDisplay.val(dateText).change();
        // 送信用hiddenフィールドに日付と曜日を設定
        $hiddenTourDate.val(dateWithDayOfWeek);
        $tourDateDisplay.next('.error-txt').hide();
      },
      monthNames: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      monthNamesShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      dayNames: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
      dayNamesMin: ["日", "月", "火", "水", "木", "金", "土"],
      showMonthAfterYear: true,
      yearSuffix: '年',
      prevText: '',
      nextText: '',
      showButtonPanel: false
    });
  });
});

/**
 * プランページのフッタースタイル調整
 */
$(document).ready(function () {
  var $title = $(".footer-original-cta-title");
  var $items = $(".footer-original-cta-items");
  var $item = $items.find(".footer-original-cta-item");

  // 余白の調整
  if ($title.length > 0 && $items.length > 0) {
    $title.css("margin-bottom", "1rem");
  }

  // ボタン幅の調整
  if ($item.length === 2) {
      $item.css("width", "48%"); 
  } else if($item.length === 1) {
      $item.css("width", "100%"); 
  } else{
    $item.css("width", "32%"); 
  }
  $item.css("display", "flex");

});

/**
 * youtube用モーダルスクリプト
 */
document.addEventListener("DOMContentLoaded", function () {
  const modal = document.getElementById("youtube-modal");
  const modalContent = document.querySelector(".youtube-modal-content");
  const closeBtn = document.querySelector(".youtube-modal-close");
  const iframe = document.getElementById("youtube-frame");
  const thumbnail = document.querySelector(".youtube-thumbnail");
  const thumbnails = document.querySelectorAll(".youtube-thumbnail");

  // サムネイルをクリックでモーダルを開く
  thumbnails.forEach(thumbnail => {
    thumbnail.addEventListener("click", function () {
      const videoId = this.getAttribute("data-video");
      iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1&rel=0`;
      modal.style.display = "flex";
      document.body.classList.add("youtube-modal-open"); // スクロールとクリックを無効化
    });
  });

  // クローズ処理（共通関数）
  function closeModal() {
      modal.style.display = "none";
      iframe.src = ""; // 動画を停止
      document.body.classList.remove("youtube-modal-open"); // スクロールとクリックを有効化
  }

  // クローズボタンをクリックで閉じる
  closeBtn.addEventListener("click", closeModal);

  // モーダル外をクリックで閉じる
  modal.addEventListener("click", function (e) {
      if (!modalContent.contains(e.target)) {
          closeModal();
      }
  });
});